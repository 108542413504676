import React, {
  ReactNode,
  FC,
  useState,
  ReactText,
  useEffect,
  useRef
} from 'react'
import clsx from 'clsx'

import useUpdateEffect from '@/hooks/useUpdateEffect'

/* assets */
import { ReactComponent as DownIcon } from 'public/assets/icons/down.svg'

/* styling */
import styles from './_.module.css'

interface IProps {
  title: string | ReactNode
  children: string | ReactNode
  className?: string
  elementKey?: ReactText
  activeKey?: ReactText
  defaultOpen?: boolean
  handleChange?: (key?: ReactText, isOpen?: boolean) => void
  showIcon?: boolean
  Icon?: FC
  IconPosition?: string
  IconClass?: string
  titleClass?: string
}

const Accordion: FC<IProps> = ({
  children,
  className,
  title,
  elementKey,
  activeKey,
  defaultOpen,
  handleChange,
  showIcon = true,
  Icon = DownIcon,
  IconPosition = 'right',
  IconClass = 'plusIcon',
  titleClass
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const elementRef = useRef<any>(null)

  useEffect(() => {
    if (defaultOpen) {
      setShowDetails(() => true)
    }
  }, [defaultOpen])

  useUpdateEffect(() => {
    // if elementKey is not present then any number of accordion can be collapsed at once

    if (!elementKey) return
    if (activeKey !== undefined) {
      setShowDetails(activeKey === elementKey)
    } else {
      setShowDetails(!showDetails)
    }
  }, [activeKey])

  const handleAccordionChange = () => {
    handleChange?.(elementKey, !showDetails)
    setShowDetails(!showDetails)
  }

  return (
    <div className={clsx('accordion', styles.accordion, className)}>
      <div
        className={clsx(showDetails && 'active', 'accordion__header')}
        tabIndex={0}
        role="button"
        onClick={handleAccordionChange}
        onKeyPress={handleAccordionChange}
      >
        {showIcon && IconPosition === 'left' ? (
          <Icon
            className={clsx(
              'inline-block',
              styles.icon,
              showDetails && 'active',
              'accordion__icon'
            )}
          />
        ) : null}

        {title ? (
          <span
            className={clsx(titleClass, 'accordion__title')}
            dangerouslySetInnerHTML={{ __html: title as string }}
          ></span>
        ) : null}
        {showIcon && IconPosition === 'right' ? (
          <Icon
            className={clsx(
              IconClass,
              'inline-block',
              styles.icon,
              showDetails && 'active',
              'accordion__icon'
            )}
          />
        ) : null}
      </div>

      <div
        ref={elementRef}
        className={clsx(
          'accordion__content',
          showDetails && 'accordion__content--expanded'
        )}
        style={{
          maxHeight: showDetails ? elementRef.current.scrollHeight + 'px' : 0
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
