import Link from '@/atoms/Link/Link'
import FooterExtra from '@/cmp/molecules/Footer/FooterExtra'
import FooterRights from '@/cmp/molecules/Footer/FooterRights'

/* utils */
import { dataLayerFooterClickEvent } from '@/utils/functions/dataLayerEvents'

interface Props {
  seoServices: FooterLink[]
  localization: FooterLink[]
  services: FooterLink[]
  resources: FooterLink[]
  demand_gen_contents: FooterLink[]
  videos: FooterLink[]
  languages: FooterLink[]
}

interface FooterSectionProps {
  title: string
  links: FooterLink[]
}

function Footer({ resources }: Props): JSX.Element {
  return (
    <footer>
      <section className="container grid sm:grid-cols-2 lg:grid-cols-4 gap-x-8 | px-5 pt-8 pb-6 sm:px-10 lg:px-6 mx-auto">
        <div>
          <FooterSection title="Product" links={productLinks} />
          <FooterSection title="About Pepper Content" links={aboutLinks} />
        </div>
        <div>
          <FooterSection title="Features" links={featureLinks} />
          {/* <FooterSection title="Videos" links={videos} /> */}
        </div>
        <div>
          <FooterSection title="Services" links={servicesLinks} />
          {/* <FooterSection title="Languages" links={languages} /> */}
        </div>
        <div>
          <FooterSection title="Resources" links={resources} />
        </div>
      </section>

      <hr className="container  |  border-blue-20  |  px-5 lg:mx-auto" />

      <FooterExtra />

      <hr className="container  |  border-blue-20   |  px-5 lg:mx-auto" />

      <FooterRights />
    </footer>
  )
}

function FooterSection({ title, links }: FooterSectionProps): JSX.Element {
  return (
    <div className="mb-6 lg:mb-8">
      <h3 className="text-yellow-70 text-small font-medium uppercase  | mb-5 focus:text-yellow-50 hover:text-yellow-50">
        {title}
      </h3>
      <ul className="space-y-2">
        {links.map(item => (
          <li key={item.slug}>
            <Link
              className="text-blue-web-90 text-sm | inline-block focus:outline-none focus:text-blue-50 hover:text-blue-50"
              href={item.slug}
              onClick={() => {
                dataLayerFooterClickEvent({
                  navigationMenuName: item.page_title,
                  subCategory1: title
                })
              }}
            >
              {item.page_title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const productLinks = [
  {
    slug: '/product/content-marketing-platform/',
    page_title: 'Content Marketing Platform'
  },
  {
    slug: '/product/content-os/',
    page_title: 'Content OS'
  },
  {
    slug: '/product/organic-marketing-stack/',
    page_title: 'Organic Marketing Stack'
  },
  {
    slug: '/product/talent-marketplace/',
    page_title: 'Expert Talent'
  }
]

const aboutLinks = [
  { slug: '/customers/', page_title: 'Customers' },
  { slug: '/careers/', page_title: 'Culture & Careers' }
]

const servicesLinks = [
  {
    slug: '/writing-services/blog-writing-services/',
    page_title: 'Content writing'
  },
  { slug: '/video-services/', page_title: 'Video Production' },
  {
    slug: '/language-services/',
    page_title: 'Language Translation'
  }
]

const featureLinks = [
  { slug: '/pepper-ai/', page_title: 'Pepper AI' },
  { slug: '/peppertype-ai/', page_title: 'Peppertype AI' },
  { slug: '/features/content-idea-generator/', page_title: 'Content Idea lab' },
  { slug: '/features/seo-content-editor/', page_title: 'Content Editor' },
  {
    slug: '/features/content-audit/',
    page_title: 'Content Audit'
  },
  {
    slug: '/features/measure-content-roi/',
    page_title: 'Content RoI and Analytics'
  },
  {
    slug: '/features/content-grader/',
    page_title: 'Content Grader'
  }
]

export default Footer
