import clsx from 'clsx'

import Link from '@/atoms/Link/Link'
import { dataLayerSocialClickEvent } from '@/utils/functions/dataLayerEvents'
import { ReactComponent as InstagramIcon } from 'public/assets/influencer/icons/social/Instagram.svg'
import { ReactComponent as TwitterIcon } from 'public/assets/influencer/icons/social/Twitter.svg'
import { ReactComponent as LinkedInIcon } from 'public/assets/influencer/icons/social/Linkedin.svg'
import { ReactComponent as FacebookIcon } from 'public/assets/influencer/icons/social/Facebook.svg'

function FooterExtra(): JSX.Element {
  const listColor = 'text-blue-web-90'
  return (
    <section className="container grid gap-x-8 sm:grid-cols-2 lg:grid-cols-4 | px-5 pt-8 pb-6 sm:px-10 lg:px-6 mx-auto">
      <div className="mb-6 lg:mb-0 ">
        <h3 className="text-small font-medium uppercase text-yellow-70 |  mb-4">
          Company
        </h3>
        <ul>
          <li className="mb-2">
            <Link
              className={clsx(
                'text-sm  |  inline-block  focus:text-blue-50',
                'focus:outline-none hover:text-blue-50',
                listColor
              )}
              href="/about-us/"
            >
              About
            </Link>
          </li>
          <li className="mb-2">
            <Link
              className={clsx(
                'text-sm  |  inline-block  focus:text-blue-50',
                'focus:outline-none hover:text-blue-50',
                listColor
              )}
              href="/careers/"
            >
              {`Careers – We're hiring!`}
            </Link>
          </li>
          <li className="mb-2">
            <a
              className={clsx(
                'text-xs font-medium   |  rounded   |  px-2 py-1',
                'bg-blue-40 text-white'
              )}
              href="https://creators.peppercontent.io"
              target="_blank"
              rel="noreferrer"
            >
              Join as a Creator&nbsp;
              <span role="img" aria-label="creators">
                ✍️
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div className="mb-6 lg:mb-0">
        <h3 className="text-small font-medium uppercase text-yellow-70 |  mb-4">
          Address
        </h3>
        <address
          className={clsx('text-sm not-italic  |  inline-block', listColor)}
        >
          1013 Centre Road, Suite 403-B
          <br />
          Wilmington County of New Castle Delaware 19805
          <br />
          United States
        </address>
      </div>

      <div className="mb-6 lg:mb-0">
        <h3 className="text-small font-medium uppercase text-yellow-70 |  mb-4">
          Contact
        </h3>
        <div className={clsx('text-sm  |  inline-block', listColor)}>
          For Any Queries
          <br />
          please connect with us at&nbsp;
          <a
            className={clsx(
              'lg:block',
              'text-blue-40',
              'focus:underline focus:outline-none hover:underline'
            )}
            href="mailto:support@peppercontent.in"
          >
            connect@peppercontent.io
          </a>
        </div>
      </div>

      <div className="mb-6 lg:mb-0">
        <h3 className="text-small font-medium uppercase text-yellow-70 |  mb-4">
          Social
        </h3>
        <div className="flex space-x-4 md:space-x-6">
          <Link
            href="https://www.instagram.com/pepper_content/"
            target="_blank"
            title="instagram"
            rel="noopener noreferrer"
            onClick={() => dataLayerSocialClickEvent({ platform: 'instagram' })}
          >
            <InstagramIcon className="h-3 w-3 lg:h-6 lg:w-6 | text-blue-web-27" />
          </Link>

          <Link
            href="https://www.linkedin.com/company/peppercontent/"
            target="_blank"
            title="linkedin"
            rel="noopener noreferrer"
            onClick={() => dataLayerSocialClickEvent({ platform: 'linkedin' })}
          >
            <LinkedInIcon className="h-3 w-3 lg:h-6 lg:w-6 | text-blue-web-27" />
          </Link>

          <Link
            href="https://twitter.com/Pepper_Content"
            title="twitter"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => dataLayerSocialClickEvent({ platform: 'twitter' })}
          >
            <TwitterIcon className="h-3 w-3 lg:h-6 lg:w-6 | text-blue-web-27" />
          </Link>

          <Link
            href="https://www.facebook.com/Pepper-Co-322356101506651/"
            title="facebook"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => dataLayerSocialClickEvent({ platform: 'facebook' })}
          >
            <FacebookIcon className="h-3 w-3 lg:h-6 lg:w-6 | text-blue-web-27" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default FooterExtra
